import React, { useContext, useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Button, message } from 'antd';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import Api from '@/services/api';
import { ManagementContext } from './context';
import { TreePermissions } from './TreePermissions';
import Spinner from '@/components/SpinnerDeprecated';
import { Worker, Company, Sector, Line } from './types';

const { Option } = Select;

const initialValues = {
	register_id: '',
	name: '',
	pcd: '',
	gender: '',
	restrict: '',
	company: '',
	restriction_type: '',
	restriction_end_date: ''
};

const formatDate = (parameter: string): string =>
	new Date(parameter).toLocaleDateString(navigator.language, {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric'
	});

export function Update() {
	const [form] = Form.useForm();
	const [tree, setTree] = useState<{ title: string; value: string; key: string }[]>([]);
	const [loading, setLoading] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [workstationsWorker, setWorkstationsWorker] = useState<string[] | null>(null);

	const {
		worker,
		companies,
		sectors,
		lines,
		workstations,
		workerUpdateModal,
		closeWorkerEditModal,
		setTriggerReload,
		setSelectCompanyId,
		setSelectSectorId,
		setSelectLineId,
		setErrors
	} = useContext(ManagementContext);

	const organizationId = useSelector(({ organization }: any) => organization.organization?.id);

	function onFilterOptionTree(array: any[] = []) {
		const options = array.map((item) => item.value ?? item);
		return options;
	}

	function optionTree({ id, name }: { id: string; name: string }) {
		return {
			title: name,
			value: id,
			key: id
		};
	}

	function onMountingTree(listWorker: string[] = [], workstations: any[] = []) {
		const canMountingTree = !workerUpdateModal && Array.isArray(workstations) && workstations.length === 0;

		if (canMountingTree) return;

		const treeWorkstations = workstations.map(optionTree);

		setTree(treeWorkstations);

		if (!Array.isArray(listWorker) || listWorker.length === 0) return;

		const treeValue = workstations.filter(({ id }) => listWorker.includes(id)).map(optionTree);

		form.setFieldsValue({ workstations: treeValue });
	}

	function resetFieldsForm(field: string) {
		const options: Record<string, string[]> = {
			company: ['sector', 'line', 'workstations'],
			sector: ['line', 'workstations'],
			line: ['workstations']
		};

		form.resetFields(options[field] || []);
	}

	function getRestriction(restriction_type: string, restriction_end_date: string) {
		return restriction_end_date
			? `${I18n.get(restriction_type)} ${formatDate(restriction_end_date)}`
			: restriction_type
			? I18n.get(restriction_type)
			: I18n.get('Not restricted');
	}

	function normalizeWorker(payload: Worker) {
		const {
			register_id,
			name,
			gender,
			restrict,
			company_id,
			sector_id,
			line_id,
			pcd,
			workstations,
			restriction_type,
			restriction_end_date
		} = payload;

		setSelectCompanyId(company_id);
		setSelectSectorId(sector_id);
		setSelectLineId(line_id);

		setWorkstationsWorker(workstations);

		form.setFieldsValue({
			register_id,
			name,
			gender,
			restrict,
			company: company_id,
			sector: sector_id,
			line: line_id,
			pcd: JSON.stringify(pcd),
			restriction: getRestriction(restriction_type, restriction_end_date)
		});
	}

	async function getWorker(worker: any) {
		if (!workerUpdateModal || !organizationId || !worker) return;
		const url = `/worker/${worker.id}?organization_id=${organizationId}`;
		try {
			setLoading(true);
			const { data } = await Api.get(url);
			normalizeWorker(data);
		} catch (errors) {
			console.log(errors);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		getWorker(worker);
	}, [workerUpdateModal]);

	useEffect(() => {
		onMountingTree(workstationsWorker || [], workstations);
	}, [workstations]);

	function onClose() {
		closeWorkerEditModal();
	}

	function normalizingValuesForm(values: any) {
		const { register_id, name, pcd, gender, restrict, company, sector, line, workstations } = values;

		const payload = {
			organization_id: organizationId,
			company_id: company,
			sector_id: sector,
			line_id: line,
			workstations: onFilterOptionTree(workstations),
			worker: {
				id: worker!.id,
				register_id,
				name,
				pcd: JSON.parse(pcd),
				gender,
				restrict
			}
		};

		return payload;
	}

	async function onSave(values: any) {
		try {
			setLoadingSubmit(true);
			const body = normalizingValuesForm(values);
			const { data } = await Api.put(`/worker/${worker!.id}`, body);
			message.success(I18n.get(data.message));
			closeWorkerEditModal();
			setTriggerReload();
		} catch (errors) {
			setErrors(errors);
		} finally {
			setLoadingSubmit(false);
		}
	}

	function onSubmit() {
		form.validateFields()
			.then(onSave)
			.catch((errInfo) => {});
	}

	const ContainerStatus: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
		<div style={{ minHeight: '250px' }}>{children}</div>
	);

	const Loading: React.FC = () => (
		<ContainerStatus>
			<Spinner />
		</ContainerStatus>
	);

	return (
		<Modal
			onOk={onSubmit}
			onCancel={onClose}
			destroyOnClose={true}
			open={workerUpdateModal}
			title={I18n.get('Update worker')}
			footer={[
				<Button key="cancel" onClick={onClose}>
					{I18n.get('Cancel')}
				</Button>,
				<Button key="save" type="primary" onClick={onSubmit} loading={loadingSubmit}>
					{I18n.get('Save')}
				</Button>
			]}
		>
			{loading ? (
				<Loading />
			) : (
				<Form form={form} layout="vertical" name="update_worker" initialValues={initialValues}>
					<Form.Item
						name="register_id"
						label={I18n.get('Register')}
						rules={[
							{
								required: true,
								message: I18n.get('Please enter a valid registration id'),
								pattern: new RegExp(/^[0-9]+$/)
							}
						]}
					>
						<Input placeholder={I18n.get('Register')} maxLength={7} disabled />
					</Form.Item>
					<Form.Item
						name="name"
						label={I18n.get('Name')}
						rules={[
							{
								required: true,
								message: I18n.get('Please enter a valid name')
							}
						]}
					>
						<Input placeholder={I18n.get('Name')} />
					</Form.Item>
					<Form.Item
						name="pcd"
						label={I18n.get('PWD')}
						rules={[
							{
								required: true,
								message: I18n.get('Select an option')
							}
						]}
					>
						<Select
							showSearch
							style={{ width: '100%' }}
							placeholder={I18n.get('Select an option')}
							optionFilterProp="children"
						>
							<Select.Option value="true">{I18n.get('Yes')}</Select.Option>
							<Select.Option value="false">{I18n.get('No')}</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						name="gender"
						label={I18n.get('Gender')}
						rules={[
							{
								required: true,
								message: I18n.get('Select an option')
							}
						]}
					>
						<Select
							showSearch
							style={{ width: '100%' }}
							placeholder={I18n.get('Select an option')}
							optionFilterProp="children"
						>
							<Select.Option value="MALE">{I18n.get('Male')}</Select.Option>
							<Select.Option value="FEMALE">{I18n.get('Female')}</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item name="restriction" label={I18n.get('Restrict')}>
						<Input disabled />
					</Form.Item>
					<Form.Item
						name="company"
						label={I18n.get('Industrial site')}
						rules={[
							{
								required: true,
								message: I18n.get('Select a industrial site')
							}
						]}
					>
						<Select
							style={{ width: '100%' }}
							onChange={(value) => {
								setSelectCompanyId(value);
								resetFieldsForm('company');
							}}
							placeholder={I18n.get('Select a industrial site')}
						>
							{companies.map((company: Company, index: any) => (
								<Option key={index} value={company.id}>
									{company.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name="sector"
						label={I18n.get('Sector')}
						rules={[
							{
								required: true,
								message: I18n.get('Select a sector')
							}
						]}
					>
						<Select
							style={{ width: '100%' }}
							onChange={(value) => {
								setSelectSectorId(value);
								resetFieldsForm('sector');
							}}
							placeholder={I18n.get('Select a industrial site')}
						>
							{sectors.map((sector: Sector, index: any) => (
								<Option key={index} value={sector.id}>
									{sector.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name="line"
						label={I18n.get('Line')}
						rules={[
							{
								required: true,
								message: I18n.get('Select a line')
							}
						]}
					>
						<Select
							style={{ width: '100%' }}
							onChange={(value) => {
								setSelectLineId(value);
								resetFieldsForm('line');
							}}
							placeholder={I18n.get('Select a line')}
						>
							{lines.map((line: Line, index: any) => (
								<Option key={index} value={line.id}>
									{line.name}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name="workstations"
						label={I18n.get('Workstation')}
						rules={[
							{
								required: true,
								message: I18n.get('Select a workstation')
							}
						]}
					>
						<TreePermissions
							tree={tree}
							placeholder={I18n.get('Select a workstation')}
							onChange={(value) => form.setFieldsValue({ workstations: value })}
						/>
					</Form.Item>
				</Form>
			)}
		</Modal>
	);
}
